import { Domain } from 'core'
import React from 'react'

import { App } from '~/navigation'

export interface IPageProps {
  domain: Domain
}

export const Page: React.FC<IPageProps> = props => <App {...props} />
