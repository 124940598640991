import { Domain } from 'core'
import React from 'react'

import { Root, useRoutes } from './Root'

export { useRoutes }

export interface IAppProps {
  domain: Domain
  subdomain?: string
}

export const App: React.FC<IAppProps> = props => <Root {...props} />
